import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import ReaderView from '../views/ReaderView.vue';

const routes = [
    {
        path: '/scan/:id',
        component: HomeView,
        props: true

    },
    {
        path: '/reader',
        component: ReaderView,
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;