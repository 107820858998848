<template>
    <div :style="containerStyle">
        <!-- Your content here -->
    </div>
</template>

<script>
import axios from 'axios';


export default {
    props: ['id'],
    data() {
        return {
            backgroundColor: 'white', // Default color
        };
    },
    computed: {
        containerStyle() {
            return {
                backgroundColor: this.backgroundColor,
                height: '100vh',
                margin: '0',
                padding: '0',
            };
        }
    },
    mounted() {
        this.checkPuedeEntrar(this.id);
    },
    methods: {
        async checkPuedeEntrar(id) {
            try {
                let config = {
                    method: 'get',
                    maxBodyLength: Infinity,
                    url: `${process.env.VUE_APP_URL}/api/puedeEntrar/${id}`,
                    headers: {}
                };

                const response = await axios(config);
                if (response.data.puede_entrar) {
                    this.backgroundColor = 'green';
                } else if (response.data.puede_entrar === false) {
                    this.backgroundColor = 'red';
                } else {
                    this.backgroundColor = 'white';
                }
            } catch (error) {
                this.backgroundColor = 'blue'; // Set to blue if there's an error
                console.error('Error fetching data:', error);
            }
        }
    }
}
</script>

