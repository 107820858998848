<template>
    <div class="container">
        <div class="top-half">
            <qrcode-stream :paused="paused" @detect="onDetect" @camera-on="onCameraOn" @camera-off="onCameraOff"
                @error="onError">
                <div v-show="showScanConfirmation" class="scan-confirmation"></div>
            </qrcode-stream>
        </div>
        <div class="bottom-half" :style="{ backgroundColor: bottomHalfBackgroundColor }">
            <!-- <p class="decode-result">
                Last result: <b>{{ result }}</b>
            </p> -->
        </div>
    </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader';
import axios from 'axios';

export default {
    components: { QrcodeStream },

    data() {
        return {
            paused: false,
            result: '',
            showScanConfirmation: false,
            isSuccess: false, // Track if the last QR scan was successful,
            bottomHalfBackgroundColor: 'blue'
        };
    },


    methods: {
        onCameraOn() {
            this.showScanConfirmation = false;
        },

        onCameraOff() {
            this.showScanConfirmation = true;
        },

        onError(error) {
            console.error(error);
        },

        async onDetect(detectedCodes) {
            this.result = JSON.stringify(detectedCodes.map((code) => code.rawValue));

            // Post the QR code result to the server
            this.isSuccess = await this.postQrCode(this.result);

            this.isSuccess ? this.bottomHalfBackgroundColor = 'green' : this.bottomHalfBackgroundColor = 'red';

            // Pause scanning for a short period
            this.paused = true;
            await this.timeout(1000);

            this.paused = false;
            await this.timeout(100);
            this.bottomHalfBackgroundColor = 'blue';
        },

        timeout(ms) {
            return new Promise((resolve) => {
                window.setTimeout(resolve, ms);
            });
        },

        async postQrCode(qrCodeData) {
            try {
                // Clean up QR code data
                qrCodeData = qrCodeData.substring(2, qrCodeData.length - 2);
                // Get last 5 characters
                qrCodeData = qrCodeData.substring(qrCodeData.length - 5);

                let config = {
                    method: 'get',
                    maxBodyLength: Infinity,
                    url: `${process.env.VUE_APP_URL}/api/puedeEntrar/${qrCodeData}`,
                    headers: {}
                };

                console.log(qrCodeData);
                const response = await axios(config);

                // Assuming the API returns a boolean `puede_entrar` to determine success
                return response.data.puede_entrar;
            } catch (error) {
                console.error('Error posting QR code data:', error);
                return false;
            }
        },
    },
};
</script>

<style scoped>
.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
}

.top-half {
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    /* Ensure the camera feed takes up the full top half */
}

.qrcode-stream {
    width: 100%;
    height: 100%;
}

.bottom-half {
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: #333;
}

.decode-result {
    font-weight: bold;
}

.scan-confirmation {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
